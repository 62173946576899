import { Injectable } from '@angular/core';
import {ServicesService} from '../users/services.service';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {ConfigServices} from '../core/config';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {Application, Documets, Spouse} from './application/application';
import {StudyTerm} from '../admin-config/study-terms/study-term/study-term';
import {Attachment} from '../core/models';
import { HigherEducationInstitutionType } from '../higher-education-institution-types/higher-education-institution-type/higher-education-institution-type';
import { HigherEducationInstitution } from '../higher-education-institutions/higher-education-institution/higher-education-institution';
import { Observable } from 'rxjs';
declare var moment: any;
@Injectable({
  providedIn: 'root'
})
export class ApplicationsService extends ServicesService {

  application: Application = new Application();
  submitted = false;
  constructor(public http: HttpClient, public configService: ConfigServices, public cookieService: CookieService, private router: Router) {
    super('applications', http, configService);
  }

  public updateApplicationService(documents : Documets){
    this.application.mother = new Spouse();
            this.application.father  = new Spouse();
            this.application.guardian  = new Spouse();
            this.application.spouse  = new Spouse();
            this.application.vulnerableChildOrNonSassDeclaration = null;
            this.application.disabilityAnnexure = null;
            this.application.provisionalOfferLetter = null;
            if (documents.mother) {
              this.application.mother = documents.mother;
            }
            if (documents.father) {
              this.application.father = documents.father;
            }
            if (documents.guardian) {
              this.application.guardian = documents.guardian;
            }
            if (documents.spouse) {
              this.application.spouse = documents.spouse;
            }

            if (documents.requiredDocuments) {
              documents.requiredDocuments.forEach((attachment: Attachment)=>{
                  if(attachment.documentType ==='VULNERABLE_CHILD_OR_NON_SASSA_DECLARATION') {
                    this.application.vulnerableChildOrNonSassDeclaration = attachment;
                  }else if(attachment.documentType ==='DISABILITY_ANNEXURE') {
                    this.application.disabilityAnnexure = attachment;
                  }else if(attachment.documentType ==='PROVISIONAL_OFFER_LETTER') {
                    this.application.provisionalOfferLetter = attachment;
                  }else if(attachment.documentType ==='SARS_CONSENT_FORM') {
                    this.application.consentForm = attachment;
                  }
              })
            }
  }

  public update(item: any) {
    const url = this.configService.getApi() + this.type;
    return this.http.put(url, item,{  withCredentials: true });
  }

  public patch(item: any) {
    const url = this.configService.getApi() + this.type;
    return this.http.patch(url, item,{  withCredentials: true });
  }

  public updateFunder(item: any) {
    const url = this.configService.getApi() + this.type;
    return this.http.patch(url+'/update-funder', item,{  withCredentials: true });
  }

  public withdraw(item: Application) {
    const url = this.configService.getApi() + this.type;
    return this.http.post(url+'/withdraw/'+item.id, null,{  withCredentials: true });
  }

  canBeSubmitted(supportingDocs: Array<Attachment>) {
    if(supportingDocs.length < 1) {
      return false;
    }
    let ret = false;
    supportingDocs.forEach((attachment: Attachment)=>{
      if(attachment.id) {
        ret = true;
        return ret;
      }
    });
    return ret;
  }

  isContinuingStudent(){
    const url =  this.configService.getApi()+this.type;
    return this.http.get(url+'/continuing-record/2025',{withCredentials:true});
  }

  public save() {

    this.application.source = 'WEB';
    if(this.application?.father?.supportingDocs && this.canBeSubmitted(this.application?.father?.supportingDocs)) {
      this.application.father.requiredDocuments = this.application?.father?.supportingDocs;
    }
    if(this.application?.mother?.supportingDocs && this.canBeSubmitted(this.application?.mother?.supportingDocs)) {
      this.application.mother.requiredDocuments = this.application?.mother?.supportingDocs;
    }
    if(this.application?.spouse?.supportingDocs && this.canBeSubmitted(this.application?.spouse?.supportingDocs)) {
      this.application.spouse.requiredDocuments = this.application?.spouse?.supportingDocs;
    }
    if(this.application?.guardian?.supportingDocs && this.canBeSubmitted(this.application?.guardian?.supportingDocs)) {
      this.application.guardian.requiredDocuments = this.application?.guardian?.supportingDocs;
    }
    const app = JSON.parse(JSON.stringify(this.application));
    if(app.guardian && !app.guardian.idNumber) {
      app.guardian = null;
    }
    if(app.spouse && !app.spouse.idNumber) {
      app.spouse = null;
    }
    if(app.mother && !app.mother.idNumber) {
      app.mother = null;
    }
    if(app.father && !app.father.idNumber) {
      app.father = null;
    }
    const url = this.configService.getApi() + this.type;
    return this.http.post(url+'/save',app,{  withCredentials: true });
  }

  public validate() {

    const app = JSON.parse(JSON.stringify(this.application));
    if(app.guardian && !app.guardian.idNumber) {
      app.guardian = null;
    }
    if(app.spouse && !app.spouse.idNumber) {
      app.spouse = null;
    }
    if(app.mother && !app.mother.idNumber) {
      app.mother = null;
    }
    if(app.father && !app.father.idNumber) {
      app.father = null;
    }
    const url = this.configService.getApi() + this.type;
    return this.http.post(url+'/validate',app,{  withCredentials: true });
  }


  public process(id: string) {
    const url = this.configService.getApi() + this.type;
    return this.http.post(url+'/process/'+id,null,{  withCredentials: true });
  }

  public getApplicationDetails(id: string) {
    const url = this.configService.getApi() + this.type + '/'+id+'/details';
    return this.http.get(url,{  withCredentials: true });

  }

  public getApplicationStatusCount(year: number,studyTerm: StudyTerm) {

    let httpParams = new HttpParams();
    httpParams = httpParams.set('year', year);
    if(studyTerm != null) {
      httpParams = httpParams.set('studyTerm', studyTerm.id);
    }
    const url = this.configService.getApi() + this.type;
    return this.http.get(url + '/application-status-count', {params: httpParams,withCredentials: true});
  }
  public getApplicationProvinceCount(year: number,studyTerm: StudyTerm) {
    const url = this.configService.getApi() + this.type;
    let httpParams = new HttpParams();
    httpParams = httpParams.set('year', year);
    if(studyTerm != null) {
      httpParams = httpParams.set('studyTerm', studyTerm.id);
    }
    return this.http.get(url + '/application-province-count', {params: httpParams,withCredentials: true});
  }

  public getKpiCount(year: number,studyTerm: StudyTerm) {
    const url = this.configService.getApi() + this.type;
    let httpParams = new HttpParams();
    httpParams = httpParams.set('year', year);
    if(studyTerm != null) {
      httpParams = httpParams.set('studyTerm', studyTerm.id);
    }
    return this.http.get(url + '/report/kpi', {params: httpParams,withCredentials: true});
  }
  public getApplicationStudentTypeCount(year: number,studyTerm: StudyTerm) {
    const url = this.configService.getApi() + this.type;
    let httpParams = new HttpParams();
    httpParams = httpParams.set('year', year);
    if(studyTerm != null) {
      httpParams = httpParams.set('studyTerm', studyTerm.id);
    }
    return this.http.get(url + '/report/application-student-type-count', {params: httpParams,withCredentials: true});
  }

  public getApplicationGenderCount(year: number,studyTerm: StudyTerm) {
    const url = this.configService.getApi() + this.type;
    let httpParams = new HttpParams();
    httpParams = httpParams.set('year', year);
    if(studyTerm != null) {
      httpParams = httpParams.set('studyTerm', studyTerm.id);
    }
    return this.http.get(url + '/report/application-gender-count', {params: httpParams,withCredentials: true});
  }

  public getApplicationDisabledCount(year: number,studyTerm: StudyTerm) {
    const url = this.configService.getApi() + this.type;
    let httpParams = new HttpParams();
    httpParams = httpParams.set('year', year);
    if(studyTerm != null) {
      httpParams = httpParams.set('studyTerm', studyTerm.id);
    }
    return this.http.get(url + '/report/application-disabled-count', {params: httpParams,withCredentials: true});
  }

  public getApplicationSassaFundedCount(year: number,studyTerm: StudyTerm) {
    const url = this.configService.getApi() + this.type;
    let httpParams = new HttpParams();
    httpParams = httpParams.set('year', year);
    if(studyTerm != null) {
      httpParams = httpParams.set('studyTerm', studyTerm.id);
    }
    return this.http.get(url + '/report/application-sassa-funded-count', {params: httpParams,withCredentials: true});
  }

  public getApplicationRaceCount(year: number,studyTerm: StudyTerm) {
    const url = this.configService.getApi() + this.type;
    let httpParams = new HttpParams();
    httpParams = httpParams.set('year', year);
    if(studyTerm != null) {
      httpParams = httpParams.set('studyTerm', studyTerm.id);
    }
    return this.http.get(url + '/report/application-race-count', {params: httpParams,withCredentials: true});
  }

  public getApplicationRejectReasonCount(year: number,studyTerm: StudyTerm) {
    const url = this.configService.getApi() + this.type;
    let httpParams = new HttpParams();
    httpParams = httpParams.set('year', year);
    if(studyTerm != null) {
      httpParams = httpParams.set('studyTerm', studyTerm.id);
    }
    return this.http.get(url + '/report/application-reject-reason-count', {params: httpParams,withCredentials: true});
  }

  public downloadFundedList(year: number) {

    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept', 'text/csv');
    const url = this.configService.getApi() + this.type + '/funded-list-file/'+year;
    return this.http.get(url, {headers: httpHeaders, responseType: 'blob'});

  }

  public getStatuses() {

    const url = this.configService.getApi() + this.type + '/retrieve/statuses';
    return this.http.get(url,{  withCredentials: true });

  }
  public getYearCycles(year: number) {

    const url = this.configService.getApi() + this.type + '/cycles/year/'+year;
    return this.http.get(url,{  withCredentials: true });

  }


  public searchByStatus(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string, status: string) {

    if (keyword === undefined) {
      keyword = '';
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    const url = this.configService.getApi() + this.type + '/status/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField+'/'+status;
    return this.http.get(url, {params: httpParams,withCredentials: true});

  }

  public searchByYear(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string, studyTerm: StudyTerm, year: string) {

    if (keyword === undefined) {
      keyword = '';
    }


    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    httpParams = httpParams.set('year', year);
    if(studyTerm) {
      httpParams = httpParams.set('cycle', studyTerm.id);
    }
    const url = this.configService.getApi() + this.type + '/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField;
    return this.http.get(url, {params: httpParams,withCredentials: true});

  }

  public download(institutionType?: HigherEducationInstitutionType, institutionName?: HigherEducationInstitution,  status?: string, idNumber?:string ) {



    if (status === undefined) {
      status = '';
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.set('status', status);

    if (institutionType) {
      httpParams = httpParams.set('institutionType', institutionType.name);
    }
    if (institutionName) {
      httpParams = httpParams.set('institutionName', institutionName.name);
    }
    if (idNumber) {
      httpParams = httpParams.set('idNumber', idNumber);
    }

    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept', 'text/csv');

    const url = this.configService.getApi() + this.type + '/download-application-report';
    return this.http.get(url, {headers: httpHeaders,params: httpParams, responseType: 'blob'});

  }

  public filter(page: number, perPage: number, sortOrder: string, sortField: string, status?: string, institutionType?: HigherEducationInstitutionType,  institutionName?: HigherEducationInstitution, idNumber?: string) {

    let httpParams = new HttpParams();

    if (institutionType) {
      httpParams = httpParams.set('institutionType', institutionType.name);
    }
    if (institutionName) {
      httpParams = httpParams.set('institutionName', institutionName.name);
    }

    if (idNumber) {
      httpParams = httpParams.set('idNumber', idNumber);

    } else {
      httpParams = httpParams.set('idNumber', '');
    }

    if (status) {
      httpParams = httpParams.set('status', status);
    }

    const url = this.configService.getApi() + this.type + '/application-report/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField ;
    return this.http.get(url, {params: httpParams, withCredentials: true});

  }

  public filterByAppealStatusCheck(page: number, perPage: number, sortOrder: string, sortField: string, status?: string, institutionType?: HigherEducationInstitutionType,  institutionName?: HigherEducationInstitution, keyword?: string) {

    let httpParams = new HttpParams();

    if (institutionType) {
      httpParams = httpParams.set('institutionType', institutionType.name);
    }
    if (institutionName) {
      httpParams = httpParams.set('institutionName', institutionName.name);
    }

    if (keyword === undefined) {
      keyword = '';
    }

    if (status) {
      httpParams = httpParams.set('status', status);
    }

    const url = this.configService.getApi() + 'admin'+  '/' + 'appeals' + '/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField ;
    //const url = this.configService.getApi() + this.type + '/application-report/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField ;
    return this.http.get(url, {params: httpParams, withCredentials: true});

  }

  public downloadStatusCheck(institutionId:string) {
    const url = this.configService.getApi()  + `institutional-portal/download-institution-status-report/${institutionId}`;
   return this.http.get(url, {withCredentials: true, responseType: 'blob'});

 }

 public downloadAppealStatusCheck(institutionId:string, institutionName?: HigherEducationInstitution,) {
  let httpParams = new HttpParams();
  if (institutionName) {
    httpParams = httpParams.set('institutionName', institutionName.name);
  }
  const url = this.configService.getApi()  + `institutional-portal/download-institution-appeal-status-report/${institutionId}`;
   return this.http.get(url, {params: httpParams,withCredentials: true, responseType: 'blob'});
 }


 public downloadOutstandingResults(institutionId:string) {
  const url = this.configService.getApi()  + `institutional-portal/download/applications-with-outstanding-results/${institutionId}`;
   return this.http.get(url, {withCredentials: true, responseType: 'blob'});
 }

 public downloadRegistrationDetails(institutionId:string) {
  const url = this.configService.getApi()  + `institutional-portal/download-institution-registration-data/${institutionId}`;
   return this.http.get(url, {withCredentials: true, responseType: 'blob'});
 }


 public viewAcademicResultsUploadedByID(page: number, perPage: number, sortOrder: string, sortField: string,institutionId:string){
  // api/institutional-portal/student-academic-results/{idNumber}
  let httpParams = new HttpParams();
  let url = '';

  if (institutionId) {
    url = this.configService.getApi() + 'institutional-portal'+  '/' + 'academic-results' + '/' + 'by-institutionId' + '/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField + '/' + institutionId ;
  } else {
    url = this.configService.getApi() + 'institutional-portal'+  '/' + 'academic-results' + '/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField  ;

   }

  return this.http.get(url, {params: httpParams, withCredentials: true});
}

public viewRegistrationDetailsUploadedByID(page: number, perPage: number, sortOrder: string, sortField: string,institutionId:string){
  let httpParams = new HttpParams();

  const url = this.configService.getApi() + 'institutional-portal'+  '/' + 'registrations' + '/' + 'by-institutionId' + '/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField + '/' + institutionId ;
  return this.http.get(url, {params: httpParams, withCredentials: true});
}

// download view academic results


public downloadAllAcademicResults(page: number, perPage: number, sortOrder: string, sortField: string,institutionId:string) {
  let httpParams = new HttpParams();

  const url = this.configService.getApi() + 'institutional-portal'+  '/' + 'download-academic-results' + '/' + 'by-institutionId' + '/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField + '/' + institutionId ;
  return this.http.get(url, {params: httpParams, withCredentials: true});
}


public funderTransfers(funder:string,registrationId: string) {
  let httpParams = new HttpParams();
  const url = this.configService.getApi() + `funder-transfer-request/save-funder-request`;
  return this.http.put(url,{params: httpParams,  withCredentials: true });
  }
  
  getCannotAppealReason(application: Application):Observable<string>{
    const url = this.configService.getApi() + `applications/cannot-appeal-reason`;
    return this.http.post(url, application, {withCredentials: true, responseType: 'text'});
  }



getRejectReasons(page: number, perPage: number, sortOrder: string, sortField: string) {
  const url = this.configService.getApi() + 'reject-reasons/'+page+'/'+perPage+'/'+sortOrder+'/'+sortField;
  return this.http.get(url, {withCredentials: true});
}

 //localhost:8080/api/institutional-portal/download-academic-results/by-institutionId/1/10/ASC/id/59
}
