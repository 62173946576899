<div id="reasonModal" class="modal fade " xmlns="http://www.w3.org/1999/html">
  <div class="modal-dialog modal-lg">

    <div class="modal-content">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-tabs card-header-info" >
            <div class="nav-tabs-navigation">
              <div class="nav-tabs-navigation">
                <div class="nav-tabs-wrapper">
              <span class="nav-tabs-title">
                <i class="fa fa-box-open fa-2x"></i>
                    {{attachment?.caption}} request reason
              </span>
                </div>
              </div>
            </div>
          </div>
          <form class="form-horizontal" role="form" #itemForm="ngForm" autocomplete="off" >

            <div class="card-body" *ngIf="attachment  && attachment.reasons">

              <button type="button" class="btn" [ngClass]="reason.selected===true?'btn-primary':'btn-outline-dark'" *ngFor="let reason of attachment.reasons" (click)="reason.selected=!reason.selected">
                {{reason.caption}}
              </button>

            </div>
            <div class="card-footer ">
              <button type="button" (click)="hideReason()" class="btn btn-primary btn-round mt-4 ">Select</button>
            </div>

          </form>
        </div>
      </div>

    </div>
  </div>
</div>
<app-card>

  <h2 class="mb-4 text-center">Exceptions</h2>

  <div class="row justify-content-center">


      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs justify-content-center">


        <li [ngbNavItem]="1" *ngIf="!isLoading && shouldShowNavItem()">

          <a ngbNavLink>Exception</a>
          <ng-template ngbNavContent>
            <div class="row justify-content-center">
            <div class="col-md-8">
            <form (ngSubmit)="onSubmit()" #ngForm="ngForm" class="px-5 needs-validation"
              [ngClass]="validated===true?'was-validated':''">

              <div class="row">
              <div class="card mx-auto mb-5 alert-light text-dark">
                <div class="card-body-thin">
                  <div class="row">
                    <div class="col-md-1 text-center">
                      <i class="fa fa-user fa-2x pt-2"></i>
                    </div>
                    <div class="col-md-7 text-start">
                      <p class="notif-body mb-0">{{item?.application?.user?.firstName}} {{item?.application?.user?.lastName}}</p>
                      <p class="mb-1 text-muted">
                        {{item?.application?.user?.idNumber}}
                      </p>

                    </div>
                    <div class="col-md-4 text-end align-items-center align-middle">
                      <p class="mb-1 text-muted">
                        Ref: {{item?.application?.reference}}
                      </p>
                      <p class="mb-1 text-muted">
                        {{item?.application?.studyTerm?.name}},{{item?.application?.year}}
                      </p>

                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-7 text-start">
                      <p class="notif-body mb-0">Disabled</p>
                      <p class="mb-1 text-muted">
                        {{item?.application?.disabled==true?'Yes':'No'}}
                      </p>
                    </div>
                    <div class="col-md-4 text-end align-items-end">
                      <button class="btn btn-primary  btn-outline-dark" type="button" (click)="disabilityChangeRequest(item?.application)">Request update on disability</button>
                      <p class="mb-1 text-muted">Can only request for current year</p>
                    </div>
                  </div>
                </div>
              </div>
              </div>

              <!--Begin Document CheckList-->
              <div class="row" *ngIf="(item.requestedDocuments && ((item.requestedDocuments.student && item.requestedDocuments.student.length > 0) || (item.requestedDocuments.spouse && item.requestedDocuments.spouse.length > 0) || (item.requestedDocuments.father && item.requestedDocuments.father.length> 0)  || (item.requestedDocuments.mother &&item.requestedDocuments.mother.length> 0 ) || (item.requestedDocuments.guardian && item.requestedDocuments.guardian.length > 0)))
              || (item.application.disabilityAnnexure && item.application.disabilityAnnexure.id) || (item.application.vulnerableChildOrNonSassDeclaration && item.application.vulnerableChildOrNonSassDeclaration.id)">
                <div class="col-12">
                  <p class="text-primary"><strong>Document checklist</strong></p>
                </div>
              </div>

              <div class="row" *ngIf="item.application.disabilityAnnexure && item.application.disabilityAnnexure.id">
                <div class="col-12">
                  <div class="form-group text-left mb-5 text-start">
                    <div class="form-group text-start mb-5">
                      <div class="row">
                        <div class="col">
                          <div class="checkbox">
                            <input type="checkbox" class="form-check-input" id="item.application.disabilityAnnexure.caption" name="item.application.disabilityAnnexure.checked" [value]="true" [(ngModel)]="item.application.disabilityAnnexure.checked">
                            <label for="item.application.disabilityAnnexure.caption" class="cr">Disability Annexure</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="item.application.vulnerableChildOrNonSassDeclaration && item.application.vulnerableChildOrNonSassDeclaration.id">
                <div class="col-12">
                  <div class="form-group text-left mb-5 text-start">
                    <div class="form-group text-start mb-5">
                      <div class="row">
                        <div class="col">
                          <div class="checkbox">
                            <input type="checkbox" class="form-check-input" id="item.application.vulnerableChildOrNonSassDeclaration.caption" name="item.application.vulnerableChildOrNonSassDeclaration.checked" [value]="true" [(ngModel)]="item.application.vulnerableChildOrNonSassDeclaration.checked">
                            <label for="item.application.vulnerableChildOrNonSassDeclaration.caption" class="cr">{{item.application.vulnerableChildOrNonSassDeclaration.caption}}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="item.requestedDocuments && item.requestedDocuments.student">
                <div class="row" *ngFor="let requested of item.requestedDocuments.student; let i = index">
                  <div class="col-12">
                    <div class="form-group text-left mb-5 text-start">
                      <div class="form-group text-start mb-5">
                        <div class="row">
                          <div class="col">
                            <div class="checkbox">
                              <input type="checkbox" class="form-check-input" id="checkbox-fill-student-{{i}}" name="studentChecked{{i}}" [value]="true" [(ngModel)]="item.requestedDocuments.student[i].checked">
                              <label for="checkbox-fill-student-{{i}}" class="cr">{{requested.caption}}</label>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="item.requestedDocuments && item.requestedDocuments.spouse">
                <div class="row" *ngFor="let requested of item.requestedDocuments.spouse; let i = index">
                  <div class="col-12">
                    <div class="form-group text-left mb-5 text-start">
                      <div class="form-group text-start mb-5">
                        <div class="row">
                          <div class="col">
                            <div class="checkbox">
                              <input type="checkbox" class="form-check-input" id="checkbox-fill-spouse-{{i}}" name="spouseChecked{{i}}" [value]="true" [(ngModel)]="item.requestedDocuments.spouse[i].checked">
                              <label for="checkbox-fill-spouse-{{i}}" class="cr">{{requested.caption}}</label>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


              <div *ngIf="item.requestedDocuments && item.requestedDocuments.father">
                <div class="row" *ngFor="let requested of item.requestedDocuments.father; let i = index">
                  <div class="col-12">
                    <div class="form-group text-left text-start">
                      <div class="form-group text-start mb-5">
                        <div class="row">
                          <div class="col">
                            <div class="checkbox">
                              <input type="checkbox" class="form-check-input" id="checkbox-fill-father-{{i}}" name="fatherChecked{{i}}" [value]="true" [(ngModel)]="item.requestedDocuments.father[i].checked">
                              <label for="checkbox-fill-father-{{i}}" class="cr">{{requested.caption}}</label>
                            </div>
                          </div>


                        </div>


                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="item.requestedDocuments && item.requestedDocuments.mother">
                <div class="row" *ngFor="let requested of item.requestedDocuments.mother; let i = index">
                  <div class="col-12">
                    <div class="form-group text-left mb-5 text-start">
                      <div class="form-group text-start mb-5">
                        <div class="row">
                          <div class="col">
                            <div class="checkbox">
                              <input type="checkbox" class="form-check-input" id="checkbox-fill-mother-{{i}}" name="motherChecked{{i}}" [value]="true" [(ngModel)]="item.requestedDocuments.mother[i].checked">
                              <label for="checkbox-fill-mother-{{i}}" class="cr">{{requested.caption}}</label>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="item.requestedDocuments && item.requestedDocuments.guardian">
                <div class="row" *ngFor="let requested of item.requestedDocuments.guardian; let i = index">
                  <div class="col-12">
                    <div class="form-group text-left mb-5 text-start">
                      <div class="form-group text-start mb-5">
                        <div class="row">
                          <div class="col">
                            <div class="checkbox">
                              <input type="checkbox"  class="form-check-input" id="checkbox-fill-guardian-{{i}}" name="guardianChecked{{i}}" [value]="true" [(ngModel)]="item.requestedDocuments.guardian[i].checked">
                              <label for="checkbox-fill-guardian-{{i}}" class="cr">{{requested.caption}}</label>
                            </div>
                          </div>


                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="item.requestedDocuments && item.requestedDocuments.appeals">
                <div class="row" *ngFor="let requested of item.requestedDocuments.appeals; let i = index">
                  <div class="col-12">
                    <div class="form-group text-left mb-5 text-start">
                      <div class="form-group text-start mb-5">
                        <div class="row">
                          <div class="col">
                            <div class="checkbox">
                              <input type="checkbox"  class="form-check-input" id="checkbox-fill-appeals-{{i}}" name="appealsChecked{{i}}" [value]="true" [(ngModel)]="item.requestedDocuments.appeals[i].checked">
                              <label for="checkbox-fill-appeals-{{i}}" class="cr">{{requested.caption}}</label>
                            </div>
                          </div>


                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>


              <!--End Document CheckList-->

              <div *ngIf="usersService.hasAnyRole(user,['CaseWorker','AppealWorker','CaseTeamLead','CaseManager','AppealManager' ,'AppealTeamLead','CaseQualityAssurance'])" >
                <!--Begin Verify-->
                <div class="row" *ngIf="(item.requestedDocuments && ((item.requestedDocuments.student && item.requestedDocuments.student.length > 0)||(item.requestedDocuments.spouse && item.requestedDocuments.spouse.length > 0) || (item.requestedDocuments.father && item.requestedDocuments.father.length> 0)  || (item.requestedDocuments.mother &&item.requestedDocuments.mother.length> 0 ) || (item.requestedDocuments.guardian && item.requestedDocuments.guardian.length > 0)))
                || (item.application.disabilityAnnexure && item.application.disabilityAnnexure.id) || (item.application.vulnerableChildOrNonSassDeclaration && item.application.vulnerableChildOrNonSassDeclaration.id)">
                  <div class="col-12">
                    <p class="text-primary"><strong>Verify documents</strong></p>
                  </div>
                </div>

                <div class="row" *ngIf="item.application.disabilityAnnexure && item.application.disabilityAnnexure.id">
                  <div class="col-12">
                    <div class="form-group text-left mb-5 text-start">
                      <p class="text-start">Disability Annexure</p>
                      <div class="form-group text-start mb-5">
                        <div class="row">
                          <div class="col">
                            <div class="radio">
                              <input type="radio"  id="item.application.disabilityAnnexure.verified" name="item.application.disabilityAnnexure.verified" [value]="true" [(ngModel)]="item.application.disabilityAnnexure.verified">
                              <label for="item.application.disabilityAnnexure.verified" class="cr">Verified</label>
                            </div>
                          </div>

                          <div class="col">
                            <div class="radio">
                              <input type="radio"  id="item.application.disabilityAnnexure.caption-2" name="item.application.disabilityAnnexure.verified"  [value]="false" [(ngModel)]="item.application.disabilityAnnexure.verified">
                              <label for="item.application.disabilityAnnexure.caption-2" class="cr">Not Verified</label>
                            </div>
                          </div>
                        </div>



                      </div>

                    </div>
                  </div>
                </div>



                <div class="row" *ngIf="item.application.vulnerableChildOrNonSassDeclaration && item.application.vulnerableChildOrNonSassDeclaration.id">
                  <div class="col-12">
                    <div class="form-group text-left mb-5 text-start">
                      <p class="text-start">{{item.application.vulnerableChildOrNonSassDeclaration.caption}}</p>
                      <div class="form-group text-start mb-5">
                        <div class="row">
                          <div class="col">
                            <div class="radio">
                              <input type="radio"  id="item.application.vulnerableChildOrNonSassDeclaration.verified" name="item.application.vulnerableChildOrNonSassDeclaration.verified" [value]="true" [(ngModel)]="item.application.vulnerableChildOrNonSassDeclaration.verified">
                              <label for="item.application.vulnerableChildOrNonSassDeclaration.verified" class="cr">Verified</label>
                            </div>
                          </div>

                          <div class="col">
                            <div class="radio">
                              <input type="radio"  id="item.application.vulnerableChildOrNonSassDeclaration.caption-2" name="item.application.vulnerableChildOrNonSassDeclaration.verified"  [value]="false" [(ngModel)]="item.application.vulnerableChildOrNonSassDeclaration.verified">
                              <label for="item.application.vulnerableChildOrNonSassDeclaration.caption-2" class="cr">Not Verified</label>
                            </div>
                          </div>
                        </div>



                      </div>

                    </div>
                  </div>
                </div>

                <div *ngIf="item.requestedDocuments && item.requestedDocuments.student">
                  <div class="row" *ngFor="let requested of item.requestedDocuments.student; let i = index">
                    <div class="col-12">
                      <div class="form-group text-left mb-5 text-start">
                        <p class="text-start">{{requested.caption}}</p>
                        <div class="form-group text-start mb-5">
                          <div class="row">
                            <div class="col">
                              <div class="radio">
                                <input type="radio"  id="checkbox-fill-student{{i}}" name="studentVerified{{i}}" [value]="true" [(ngModel)]="item.requestedDocuments.student[i].verified">
                                <label for="checkbox-fill-student{{i}}" class="cr">Verified</label>
                              </div>
                            </div>
                            <div class="col">
                              <div class="radio">
                                <input type="radio"  id="checkbox-fill-student-2{{i}}" name="studentVerified{{i}}"  [value]="false" [(ngModel)]="item.requestedDocuments.student[i].verified">
                                <label for="checkbox-fill-student-2{{i}}" class="cr">Not Verified</label>
                              </div>
                            </div>
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="item.requestedDocuments && item.requestedDocuments.spouse">
                  <div class="row" *ngFor="let requested of item.requestedDocuments.spouse; let i = index">
                    <div class="col-12">
                      <div class="form-group text-left mb-5 text-start">
                        <p class="text-start">{{requested.caption}}</p>
                        <div class="form-group text-start mb-5">
                          <div class="row">
                            <div class="col">
                              <div class="radio">
                                <input type="radio"  id="checkbox-fill-spouse{{i}}" name="spouseVerified{{i}}" [value]="true" [(ngModel)]="item.requestedDocuments.spouse[i].verified">
                                <label for="checkbox-fill-spouse{{i}}" class="cr">Verified</label>
                              </div>
                            </div>

                            <div class="col">
                              <div class="radio">
                                <input type="radio"  id="checkbox-fill-spouse-2{{i}}" name="spouseVerified{{i}}"  [value]="false" [(ngModel)]="item.requestedDocuments.spouse[i].verified">
                                <label for="checkbox-fill-spouse-2{{i}}" class="cr">Not Verified</label>
                              </div>
                            </div>
                          </div>



                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="item.requestedDocuments && item.requestedDocuments.father">
                  <div class="row" *ngFor="let requested of item.requestedDocuments.father; let i = index">
                    <div class="col-12">
                      <div class="form-group text-left text-start">
                        <p class="text-start">{{requested.caption}}</p>
                        <div class="form-group text-start mb-5">
                          <div class="row">
                            <div class="col">
                              <div class="radio">
                                <input type="radio" id="checkbox-fill-father{{i}}" name="fatherVerified{{i}}" [value]="true" [(ngModel)]="item.requestedDocuments.father[i].verified">
                                <label for="checkbox-fill-father{{i}}" class="cr">Verified</label>
                              </div>
                            </div>

                            <div class="col">
                              <div class="radio">
                                <input type="radio" id="checkbox-fill-father-2{{i}}"  name="fatherVerified{{i}}"  [value]="false" [(ngModel)]="item.requestedDocuments.father[i].verified">
                                <label for="checkbox-fill-father-2{{i}}" class="cr">Not Verified</label>
                              </div>
                            </div>
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="item.requestedDocuments && item.requestedDocuments.mother">
                  <div class="row" *ngFor="let requested of item.requestedDocuments.mother; let i = index">
                    <div class="col-12">
                      <div class="form-group text-left mb-5 text-start">
                        <p class="text-start">{{requested.caption}}</p>
                        <div class="form-group text-start mb-5">
                          <div class="row">
                            <div class="col">
                              <div class="radio">
                                <input type="radio"  id="checkbox-fill-mother{{i}}" name="motherVerified{{i}}" [value]="true" [(ngModel)]="item.requestedDocuments.mother[i].verified">
                                <label for="checkbox-fill-mother{{i}}" class="cr">Verified</label>
                              </div>
                            </div>
                            <div class="col">
                              <div class="radio">
                                <input type="radio"  id="checkbox-fill-mother-2{{i}}" name="motherVerified{{i}}"  [value]="false" [(ngModel)]="item.requestedDocuments.mother[i].verified">
                                <label for="checkbox-fill-mother-2{{i}}" class="cr">Not Verified</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="item.requestedDocuments && item.requestedDocuments.guardian">
                  <div class="row" *ngFor="let requested of item.requestedDocuments.guardian; let i = index">
                    <div class="col-12">
                      <div class="form-group text-left mb-5 text-start">
                        <p class="text-start">{{requested.caption}}</p>
                        <div class="form-group text-start mb-5">
                          <div class="row">
                            <div class="col">
                              <div class="radio">
                                <input type="radio"  id="checkbox-fill-guardian{{i}}" name="guardianVerified{{i}}" [value]="true" [(ngModel)]="item.requestedDocuments.guardian[i].verified">
                                <label for="checkbox-fill-guardian{{i}}" class="cr">Verified</label>
                              </div>
                            </div>

                            <div class="col">
                              <div class="radio">
                                <input type="radio"  id="checkbox-fill-guardian-2{{i}}" name="guardianVerified{{i}}"  [value]="false" [(ngModel)]="item.requestedDocuments.guardian[i].verified">
                                <label for="checkbox-fill-guardian-2{{i}}" class="cr">Not Verified</label>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <!--End Verify-->

                <div class="row">
                  <div class="col-12">
                    <p class="mt-3 text-primary"><strong>Outcome</strong></p>
                    <!-- <div class="form-check">
                      <div class="radio">
                        <input type="radio"  id="checkbox-fill-request" name="approve"  [value]="false" [(ngModel)]="approve">
                        <label for="checkbox-fill-request" class="cr">Request Document</label>
                      </div>
                    </div> -->
                    <div class="form-check">
                      <div class="radio">
                        <input type="radio" required  id="checkbox-fill-request" name="status"  [value]="'RequestDocument'" [(ngModel)]="status">
                        <label for="checkbox-fill-request" class="cr">Request Document</label>
                      </div>
                    </div>
                    <div class="form-check">
                      <div class="radio">
                        <input type="radio" required id="checkbox-fill-approve" name="status"  [value]="'Approve'" [(ngModel)]="status">
                        <label for="checkbox-fill-approve" class="cr">Approve</label>
                      </div>
                    </div>
                    <!-- <div class="form-check">
                      <div class="radio">
                        <input type="radio"  id="checkbox-fill-approve" name="approve"  [value]="true" [(ngModel)]="approve">
                        <label for="checkbox-fill-approve" class="cr">Approve</label>
                      </div>
                    </div> -->
                    <div class="form-check">
                      <div class="radio">
                        <input type="radio" required id="checkbox-fill-reject" name="status"  [value]="'Reject'" [(ngModel)]="status">
                        <label for="checkbox-fill-reject" class="cr">Reject</label>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row mb-2" *ngIf="status==='Reject'">
                  <div class="col-12">
                    <p class="mt-3 text-primary"><strong>Case Reject Reason</strong></p>
                    <div class="form-group">
                      <select class="form-control" appCaseRejectReason title="Reject reason" required id="caseRejectReason" name="caseRejectReason" [(ngModel)]="item.caseRejectReason" >
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="status==='RequestDocument'">
                  <div class="col-12">
                    <p class="mt-3 text-primary"><strong>Required Document</strong></p>
                  </div>
                </div>
                
                <div class="row mb-5" *ngIf="status==='RequestDocument'">
                    <label class="form-label" >Which Documents are requested?</label>
                    <div class="row">
                      <div class="col">
                        <button type="button" class="btn" [ngClass]="requestedDocuments.disabilityAnnexure.selected===true?'btn-primary':'btn-outline-dark'" *ngIf="requestedDocuments.disabilityAnnexure" (click)="requestedDocuments.disabilityAnnexure.selected =!requestedDocuments.disabilityAnnexure.selected">
                          {{requestedDocuments.disabilityAnnexure.caption}}
                        </button>

                        <button type="button" class="btn" [ngClass]="hasReasons(attachment)===true?'btn-primary':'btn-outline-dark'" *ngFor="let attachment of requestedDocuments.spouse" (click)="selectReason(attachment)">
                          {{attachment.caption}}
                          <ul>
                            <span class="text-left" *ngFor="let reason of attachment.reasons">
                                <li *ngIf="reason.selected">
                                  {{reason.caption}}
                                </li>
                              </span>
                          </ul>
                        </button>


                        <button type="button" class="btn" [ngClass]="hasReasons(attachment)===true?'btn-primary':'btn-outline-dark'" *ngFor="let attachment of requestedDocuments.student" (click)="selectReason(attachment)">
                          {{attachment.caption}}
                          <ul>
                            <span class="text-left" *ngFor="let reason of attachment.reasons">
                                <li *ngIf="reason.selected">
                                  {{reason.caption}}
                                </li>
                              </span>
                          </ul>
                        </button>


                        <button type="button" class="btn" [ngClass]="hasReasons(attachment)===true?'btn-primary':'btn-outline-dark'" *ngFor="let attachment of requestedDocuments.father" (click)="selectReason(attachment)">
                          {{attachment.caption}}
                          <ul>
                            <span class="text-left" *ngFor="let reason of attachment.reasons">
                                <li *ngIf="reason.selected">
                                  {{reason.caption}}
                                </li>
                              </span>
                          </ul>
                        </button>
                        <button type="button" class="btn" [ngClass]="hasReasons(attachment)===true?'btn-primary':'btn-outline-dark'" *ngFor="let attachment of requestedDocuments.mother" (click)="selectReason(attachment)">
                          {{attachment.caption}}
                          <ul>
                            <span class="text-left" *ngFor="let reason of attachment.reasons">
                                <li *ngIf="reason.selected">
                                  {{reason.caption}}
                                </li>
                              </span>
                          </ul>
                        </button>
                        <button type="button" class="btn" [ngClass]="hasReasons(attachment)===true?'btn-primary':'btn-outline-dark'" *ngFor="let attachment of requestedDocuments.guardian" (click)="selectReason(attachment)">
                          {{attachment.caption}}
                          <ul>
                            <span class="text-left" *ngFor="let reason of attachment.reasons">
                                <li *ngIf="reason.selected">
                                  {{reason.caption}}
                                </li>
                              </span>
                          </ul>
                        </button>
                      </div>
                    </div>

                </div>

                <div class="row">
                  <button type="submit" class="btn btn-primary">{{status==='RequestDocument'?'Send':'Submit'}}</button>
                </div>
              </div>

            </form>
            </div>
            </div>

          </ng-template>
        </li>


        <li [ngbNavItem]="2">
          <a ngbNavLink>Applicant details</a>
          <ng-template ngbNavContent>
            <div class="row justify-content-center">
              <div class="col-md-12">
                <ngb-accordion [closeOthers]="true">
                  <ngb-panel id="static-1" title="Application details">
                    <ng-template ngbPanelContent>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-4">
                              <p class="notif-body mb-0"><strong>Year of study</strong></p>
                              <p class="mb-1 text-muted">
                                {{item?.application?.year}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0"><strong>Application Ref</strong></p>
                              <p class="mb-1 text-muted">
                                {{item?.application?.reference}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0"><strong>Id Number</strong></p>
                              <p class="mb-1 text-muted">
                                {{item?.application?.user?.idNumber}}
                              </p>
                            </div>
                          </div>


                          <div class="row mt-3">

                            <div class="col-md-4">
                              <p class="notif-body mb-0"><strong>SASSA Funded</strong></p>
                              <p class="mb-1 text-muted">
                                {{item?.application?.sassaFunded ==true?'Yes':'No'}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0"><strong>Disabled</strong></p>
                              <p class="mb-1 text-muted">
                                {{item?.application?.disabled==true?'Yes':'No'}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0"><strong>Application Status</strong></p>
                              <p class="mb-1 text-muted">
                                {{item?.application?.status}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0"><strong>Institution</strong></p>
                              <p class="mb-1 text-muted">
                                {{item?.application?.selectedHigherEducationInstitution?.name}}
                              </p>
                            </div>
                          </div>

                          <div class="row mt-3">
                            <div class="col-md-4">
                              <p class="notif-body mb-0"><strong>Assigned date</strong></p>
                              <p class="mb-1 text-muted">
                                {{item?.assignedDate | date :'dd/MM/yyyy'}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0"><strong>Due Date</strong></p>
                              <p class="mb-1 text-muted">
                                {{item?.dueDate | date :'dd/MM/yyyy'}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0"><strong>Source</strong></p>
                              <p class="mb-1 text-muted">
                                {{item?.application?.source}}
                              </p>
                            </div>
                          </div>


                        </div>
                        <div class="col-md-6 text-start"  *ngIf="item?.application?.disabilityAnnexure && item?.application?.disabilityAnnexure.id">
                          <strong> {{item.application.disabilityAnnexure.caption}}</strong>
                          <embed [src]="item.application.disabilityAnnexure.url"  [type]="item?.application?.disabilityAnnexure.contentType"  style="width:100%;height:70vh">
                          <p>
                            <a [href]="item.application.disabilityAnnexure.url" target="_blank"><i class="fa fa-download fa-2x"></i></a>
                          </p>

                        </div>



                        <div class="col-md-6 text-start"  *ngIf="item?.application?.vulnerableChildOrNonSassDeclaration && item?.application?.vulnerableChildOrNonSassDeclaration.id">
                          <strong> {{item.application.vulnerableChildOrNonSassDeclaration.caption}}</strong>
                          <embed [src]="item.application.vulnerableChildOrNonSassDeclaration.url"  [type]="item?.application?.vulnerableChildOrNonSassDeclaration.contentType"  style="width:100%;height:70vh">
                          <p>
                            <a [href]="item.application.vulnerableChildOrNonSassDeclaration.url" target="_blank"><i class="fa fa-download fa-2x"></i></a>
                          </p>
                        </div>



                      </div>

                    </ng-template>
                  </ngb-panel>

                  <ngb-panel id="static-2" title="Spouse's details" *ngIf="item?.application?.spouse">
                    <ng-template ngbPanelContent>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Id Number</p>
                              <p class="mb-1 text-muted">
                                <input type="text"  autocomplete="off" class="form-control" [disabled]="!item.application.spouse.edit" [(ngModel)]="item.application.spouse.idNumber" name="item.application.spouse.idNumber">
                              </p>
                              <p class="mb-1 text-muted" *ngIf="spouse">
                                {{spouse?.idNumber}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Names</p>
                              <p class="mb-1 text-muted">
                                <input type="text"  autocomplete="off" class="form-control" [disabled]="!item.application.spouse.edit" [(ngModel)]="item.application.spouse.firstName" name="item.application.spouse.firstName">
                              </p>
                              <p class="mb-1 text-muted" *ngIf="spouse">
                                {{spouse?.firstName}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Surname</p>
                              <p class="mb-1 text-muted">
                                <input type="text"  autocomplete="off" class="form-control" [disabled]="!item.application.spouse.edit" [(ngModel)]="item.application.spouse.lastName" name="item.application.spouse.lastName ">
                              </p>
                              <p class="mb-1 text-muted" *ngIf="spouse">
                                {{spouse?.lastName}}
                              </p>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Annual gross income</p>
                              <p class="mb-1 text-muted">
                                <input type="text"  autocomplete="off" class="form-control" [disabled]="!item.application.spouse.edit" [(ngModel)]="item.application.spouse.income" name="item.application.spouse.income ">
                              </p>
                              <p class="mb-1 text-muted" *ngIf="spouse">
                                {{spouse?.income}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Alive Status</p>
                              <p class="mb-1 text-muted" *ngIf="spouse">
                                {{spouse?.alive===true?'Alive':'Deceased'}}
                              </p>
                              <p class="mb-1 text-muted" *ngIf="item?.application?.spouse?.alive">
                                {{item?.application?.spouse?.alive===true?'Alive':'Deceased'}}
                              </p>
                            </div>
                            <div class="col-md-4 text-end">
                              <button class="btn btn-block mb-4" [ngClass]="item.application.spouse.edit==true?'btn-primary':'btn-outline-dark'" type="button" (click)="update(item.application.spouse);">{{item.application.spouse.edit==true?'Save':'Edit'}}</button>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 text-start"  *ngIf="item?.application?.spouse.supportingDocs && item?.application?.spouse.supportingDocs.length> 0">
                          <div class="row" *ngFor="let supportingDoc of item.application.spouse.supportingDocs">
                            <embed [src]="supportingDoc.url" [type]="supportingDoc.contentType" style="width:100%;height:70vh">
                            <p>
                              <a [href]="supportingDoc.url" target="_blank"><i class="fa fa-download fa-2x"></i></a>
                            </p>
                          </div>

                        </div>
                      </div>

                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="static-3" title="Father's details" *ngIf="item?.application?.father">
                    <ng-template ngbPanelContent>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Id Number</p>
                              <p class="mb-1 text-muted">

                                <input type="text"  autocomplete="off" class="form-control" [disabled]="!item.application.father.edit" [(ngModel)]="item.application.father.idNumber" name="item.application.father.idNumber">
                              </p>
                              <p class="mb-1 text-muted" *ngIf="father">
                                {{father?.idNumber}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Names</p>
                              <p class="mb-1 text-muted">
                                <input type="text" autocomplete="off" class="form-control" [disabled]="!item.application.father.edit" [(ngModel)]="item.application.father.firstName" name="item.application.father.firstName">
                              </p>
                              <p class="mb-1 text-muted" *ngIf="father">
                                {{father?.firstName}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Surname</p>
                              <p class="mb-1 text-muted">
                                <input type="text"  autocomplete="off" class="form-control" [disabled]="!item.application.father.edit" [(ngModel)]="item.application.father.lastName" name="item.application.father.lastName ">
                              </p>
                              <p class="mb-1 text-muted" *ngIf="father">
                                {{father?.lastName}}
                              </p>
                            </div>

                          </div>


                          <div class="row">
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Annual gross income</p>
                              <p class="mb-1 text-muted">
                                <input type="text" autocomplete="off" class="form-control" [disabled]="!item.application.father.edit" [(ngModel)]="item.application.father.income" name="item.application.father.income ">
                              </p>
                              <p class="mb-1 text-muted" *ngIf="father">
                                {{father?.income}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Alive Status</p>
                              <p class="mb-1 text-muted" *ngIf="father">
                                {{father?.alive===true?'Alive':'Deceased'}}
                              </p>
                              <p class="mb-1 text-muted" *ngIf="item?.application?.father?.alive">
                                {{item?.application?.father?.alive===true?'Alive':'Deceased'}}
                              </p>
                            </div>
                            <div class="col-md-4 text-end">
                              <button class="btn btn-block mb-4" [ngClass]="item.application.father.edit==true?'btn-primary':'btn-outline-dark'" type="button" (click)="update(item.application.father);">{{item.application.father.edit===true?'Save':'Edit'}}</button>
                            </div>
                          </div>


                        </div>
                        <div class="col-md-6 text-start"  *ngIf="item?.application?.father.supportingDocs && item?.application?.father.supportingDocs.length> 0">
                          <div class="row" *ngFor="let supportingDoc of item.application.father.supportingDocs">
                            <embed [src]="supportingDoc.url"  [type]="supportingDoc.contentType" style="width:100%;height:70vh">
                            <p>
                              <a [href]="supportingDoc.url" target="_blank"><i class="fa fa-download fa-2x"></i></a>
                            </p>
                          </div>

                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>

                  <ngb-panel id="static-4" title="Mother's details" *ngIf="item?.application?.mother">
                    <ng-template ngbPanelContent>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Id Number</p>
                              <p class="mb-1 text-muted">

                                <input type="text"  autocomplete="off"class="form-control" [disabled]="!item.application.mother.edit" [(ngModel)]="item.application.mother.idNumber" name="item.application.mother.idNumber">
                              </p>
                              <p class="mb-1 text-muted" *ngIf="mother">
                                {{mother?.idNumber}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Names</p>
                              <p class="mb-1 text-muted">
                                <input type="text"  autocomplete="off" class="form-control" [disabled]="!item.application.mother.edit" [(ngModel)]="item.application.mother.firstName" name="item.application.mother.firstName">
                              </p>
                              <p class="mb-1 text-muted" *ngIf="mother">
                                {{mother?.firstName}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Surname</p>
                              <p class="mb-1 text-muted">
                                <input type="text" autocomplete="off" class="form-control" [disabled]="!item.application.mother.edit" [(ngModel)]="item.application.mother.lastName" name="item.application.mother.lastName ">
                              </p>
                              <p class="mb-1 text-muted" *ngIf="mother">
                                {{mother?.lastName}}
                              </p>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Annual gross income</p>
                              <p class="mb-1 text-muted">
                                <input type="text" autocomplete="off" class="form-control" [disabled]="!item.application.mother.edit" [(ngModel)]="item.application.mother.income" name="item.application.mother.income ">
                              </p>
                              <p class="mb-1 text-muted" *ngIf="mother">
                                {{mother?.income}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Alive Status</p>
                              <p class="mb-1 text-muted" *ngIf="mother">
                                {{mother?.alive===true?'Alive':'Deceased'}}
                              </p>
                              <p class="mb-1 text-muted" *ngIf="item?.application?.mother?.alive">
                                {{item?.application?.mother?.alive===true?'Alive':'Deceased'}}
                              </p>
                            </div>
                            <div class="col-md-4 text-end">
                              <button class="btn btn-block mb-4" [ngClass]="item.application.mother.edit==true?'btn-primary':'btn-outline-dark'" type="button" (click)="update(item.application.mother);">{{item.application.mother.edit==true?'Save':'Edit'}}</button>
                            </div>
                          </div>


                        </div>
                        <div class="col-md-6 text-start"  *ngIf="item?.application?.mother.supportingDocs && item?.application?.mother.supportingDocs.length> 0">
                          <div class="row" *ngFor="let supportingDoc of item.application.mother.supportingDocs">
                            <embed [src]="supportingDoc.url"  [type]="supportingDoc.contentType" style="width:100%;height:70vh">
                            <p>
                              <a [href]="supportingDoc.url" target="_blank"><i class="fa fa-download fa-2x"></i></a>
                            </p>
                          </div>

                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>

                  <ngb-panel id="static-5" title="Gaurdian's details" *ngIf="item?.application?.guardian">
                    <ng-template ngbPanelContent>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Id Number</p>
                              <p class="mb-1 text-muted">

                                <input type="text"  autocomplete="off" class="form-control" [disabled]="!item.application.guardian.edit" [(ngModel)]="item.application.guardian.idNumber" name="item.application.guardian.idNumber">
                              </p>
                              <p class="mb-1 text-muted" *ngIf="guardian">
                                {{guardian?.lastName}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Names</p>
                              <p class="mb-1 text-muted">
                                <input type="text"  autocomplete="off" class="form-control" [disabled]="!item.application.guardian.edit" [(ngModel)]="item.application.guardian.firstName" name="item.application.guardian.firstName">
                              </p>
                              <p class="mb-1 text-muted" *ngIf="guardian">
                                {{guardian?.firstName}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Surname</p>
                              <p class="mb-1 text-muted">
                                <input type="text"  autocomplete="off" class="form-control" [disabled]="!item.application.guardian.edit" [(ngModel)]="item.application.guardian.lastName" name="item.application.guardian.lastName ">
                              </p>
                              <p class="mb-1 text-muted" *ngIf="guardian">
                                {{guardian?.lastName}}
                              </p>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Annual gross income</p>
                              <p class="mb-1 text-muted">
                                <input type="text"  autocomplete="off" class="form-control" [disabled]="!item.application.guardian.edit" [(ngModel)]="item.application.guardian.income" name="item.application.guardian.income ">
                              </p>
                              <p class="mb-1 text-muted" *ngIf="guardian">
                                {{guardian?.income}}
                              </p>
                            </div>
                            <div class="col-md-4">
                              <p class="notif-body mb-0">Alive Status</p>
                              <p class="mb-1 text-muted" *ngIf="guardian">
                                {{guardian?.alive===true?'Alive':'Deceased'}}
                              </p>
                              <p class="mb-1 text-muted" *ngIf="item?.application?.guardian?.alive">
                                {{item?.application?.guardian?.alive===true?'Alive':'Deceased'}}
                              </p>
                            </div>
                            <div class="col-md-4 text-end">
                              <button class="btn btn-block mb-4" [ngClass]="item.application.guardian.edit==true?'btn-primary':'btn-outline-dark'" type="button" (click)="update(item.application.guardian);">{{item.application.guardian.edit==true?'Save':'Edit'}}</button>
                            </div>
                          </div>


                        </div>
                        <div class="col-md-6 text-start"  *ngIf="item?.application?.guardian.supportingDocs && item?.application?.guardian.supportingDocs.length> 0">
                          <div class="row" *ngFor="let supportingDoc of item.application.guardian.supportingDocs">
                            <embed [src]="supportingDoc.url" [type]="supportingDoc.contentType" style="width:100%;height:70vh">
                            <p>
                              <a [href]="supportingDoc.url" target="_blank"><i class="fa fa-download fa-2x text-primary"></i></a>
                            </p>
                          </div>

                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>


                </ngb-accordion>

              </div>

            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="3" *ngIf="item.submittedDocuments">
          <a ngbNavLink>Supporting Documents</a>
          <ng-template ngbNavContent>
            <div class="row justify-content-center">
              <div class="col-md-12">
                <ngb-accordion [closeOthers]="true">
                  <ngb-panel id="Supporting-Documents-1" title="Supporting Documents" >
                    <ng-template ngbPanelContent>

                      <div class="row" *ngFor="let document of item.submittedDocuments?.father">
                        <div class="col-md-12 text-start">
                          <strong> {{document.name}}</strong>
                          <embed [src]="document.url"  [type]="document.contentType"  style="width:100%;height:70vh">
                          <p>
                            <a [href]="document.url" target="_blank"><i class="fa fa-download fa-2x"></i></a>
                          </p>
                        </div>
                      </div>

                      <div class="row" *ngFor="let document of item.submittedDocuments?.mother">
                        <div class="col-md-12 text-start">
                          <strong> {{document.name}}</strong>
                          <embed [src]="document.url"  [type]="document.contentType"  style="width:100%;height:70vh">
                          <p>
                            <a [href]="document.url" target="_blank"><i class="fa fa-download fa-2x"></i></a>
                          </p>
                        </div>
                      </div>

                      <div class="row" *ngFor="let document of item.submittedDocuments?.spouse">
                        <div class="col-md-12 text-start">
                          <strong> {{document.name}}</strong>
                          <embed [src]="document.url"  [type]="document.contentType"  style="width:100%;height:70vh">
                          <p>
                            <a [href]="document.url" target="_blank"><i class="fa fa-download fa-2x"></i></a>
                          </p>
                        </div>
                      </div>

                      <div class="row" *ngFor="let document of item.submittedDocuments?.guardian">
                        <div class="col-md-12 text-start">
                          <strong> {{document.name}}</strong>
                          <embed [src]="document.url"  [type]="document.contentType"  style="width:100%;height:70vh">
                          <p>
                            <a [href]="document.url" target="_blank"><i class="fa fa-download fa-2x"></i></a>
                          </p>
                        </div>
                      </div>

                      <div class="row" *ngFor="let document of item.submittedDocuments?.student">
                        <div class="col-md-12 text-start">
                          <strong> {{document.name}}</strong>
                          <embed [src]="document.url"  [type]="document.contentType"  style="width:100%;height:70vh">
                          <p>
                            <a [href]="document.url" target="_blank"><i class="fa fa-download fa-2x"></i></a>
                          </p>
                        </div>
                      </div>

                      <div class="row" *ngFor="let document of item.submittedDocuments?.appeals">
                        <div class="col-md-12 text-start">
                          <strong> {{document.name}}</strong>
                          <embed [src]="document.url"  [type]="document.contentType"  style="width:100%;height:70vh">
                          <p>
                            <a [href]="document.url" target="_blank"><i class="fa fa-download fa-2x"></i></a>
                          </p>
                        </div>
                      </div>


                    </ng-template>
                  </ngb-panel>




                </ngb-accordion>

              </div>

            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="4">
          <a ngbNavLink>Audit History</a>
          <ng-template ngbNavContent>
            <div class="main">
              <div class="row justify-content-center">
                <div class="col-md-12 text-center">
                  <div class="table-responsive">
                  <table class="table table-borderless text-start mt-4 table-hover">
                    <thead>
                    <tr>
                      <th>Audit Date</th>
                      <th>Business Event</th>
                      <th>Changed By</th>
                      <th>Comment</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let applicationEvent of applicationEvents">
                      <td>
                        {{applicationEvent.created | date :'medium'}}
                      </td>
                      <td>
                        {{getStatus(applicationEvent.status)}}
                      </td>
                      <td>
                        {{applicationEvent.worker?.firstName}} {{applicationEvent.worker?.lastName}}
                      </td>
                      <td>{{applicationEvent.comment}}</td>
                    </tr>
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>

      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>


</app-card>
