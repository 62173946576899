import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ConfigServices} from '../core/config';
import {LoginBean} from '../core/models';
import {Router} from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {CookieService} from 'ngx-cookie-service';
import {ServicesService} from './services.service';
import {Register} from './user/register';
import {User} from '../admin/users/user/user';
import {Escalate} from './user/escalate';
import { Observable } from 'rxjs';
import {InstitutionRegistration} from '../admin/contact-center/registration-details/registration-detail/institution.registration';
declare var moment: any;
@Injectable({
  providedIn: 'root'
})
export class UsersService extends ServicesService {


  public message: string;
  public action: string;
  public loginBean: LoginBean;
  public register_: Register;
  public idNumber: string;
  public robot: string;
  public tab = 1;
  public user: User = new User();
  menu: Map<string,Array<string>> = new Map<string, Array<string>>();
  constructor(public http: HttpClient, public configService: ConfigServices, public cookieService: CookieService, private router: Router) {
    super('users', http, configService);
    this.init();
  }

  public init() {
    this.menu.set('AllApplications',['Admin']);
    this.menu.set('AppealReasons',['Admin']);
    this.menu.set('Courses',['Admin']);
    this.menu.set('FrequentlyAskedQuestions',['Admin']);
    this.menu.set('FrequentlyAskedQuestionAnswer',['Admin']);
    this.menu.set('Grades',['Admin']);
    this.menu.set('HigherEducationInstitutionTypes',['Admin']);
    this.menu.set('HigherEducationInstitutions',['Admin']);
    this.menu.set('StudyTerms',['Admin']);
    this.menu.set('SecurityQuestions',['Admin']);
    this.menu.set('Races',['Admin']);
    this.menu.set('RejectionReasons',['Admin']);
    this.menu.set('RequiredDocuments',['Admin']);
    this.menu.set('WithdrawReasons',['Admin']);
    this.menu.set('TermsAndConditions',['Admin']);
    this.menu.set('Laptops',['Admin']);
    this.menu.set('Provinces',['Admin']);
    this.menu.set('Municipalities',['Admin']);
    this.menu.set('Municipalities',['Admin']);
    this.menu.set('School',['Admin']);
    this.menu.set('PlacesOfApplication',['Admin']);
    this.menu.set('SystemMessages',['Admin']);
    this.menu.set('ChannelTypes',['Admin']);
    this.menu.set('Departments',['Admin']);
    this.menu.set('QueryCategories',['Admin']);
    this.menu.set('QueryTypes',['Admin']);
    this.menu.set('RejectReasons',['Admin']);
    this.menu.set('AppealDocuments',['Admin']);
    this.menu.set('AppealRejectReasons',['Admin']);
    this.menu.set('AppealDocumentTypes',['Admin']);
    this.menu.set('Reports',['Admin','CaseTeamLead','CaseQualityAssurance','CaseManager','AppealTeamLead','AppealManager']);
    this.menu.set('AllAppeal',['ServicingAdmin','ContactCenterWorker','AppealManager','AppealTeamLead','CaseTeamLead','CaseManager','Admin','InstitutionAdministrator','CaseWorker','AppealWorker']);
    this.menu.set('AllCases',['CaseTeamLead','ContactCenterWorker','CaseManager','CaseWorker','CaseTeamLead','CaseQualityAssurance','CaseManager']),
    this.menu.set('DailyCases',['CaseTeamLead','CaseManager','CaseWorker'])
    this.menu.set('UnassignedCases',['CaseTeamLead','CaseQualityAssurance','CaseManager']);
    this.menu.set('RevokedCases',['CaseTeamLead']);
    this.menu.set('ApprovedAppeals',['AppealTeamLead']);
    this.menu.set('RejectedAppeals',['AppealTeamLead']);
    this.menu.set('FinalizedAppeals',['AppealTeamLead']);
    this.menu.set('AssignedCases',['CaseWorker','CaseTeamLead','CaseQualityAssurance','CaseManager']);
    this.menu.set('ReviewedCases',['CaseWorker','CaseTeamLead','CaseQualityAssurance','CaseManager']);
    this.menu.set('OutOfSLACases',['CaseWorker','CaseTeamLead','CaseQualityAssurance','CaseManager']);
    this.menu.set('RejectedCases',['CaseWorker','CaseTeamLead']);
    this.menu.set('AssignedAppeals',['AppealWorker','AppealTeamLead']);
    this.menu.set('ReviewedAppeals',['AppealWorker','AppealTeamLead']);
    this.menu.set('OutOfSLAAppeals',['AppealWorker','AppealTeamLead']);
    this.menu.set('StudentDetails',['ContactCenterWorker','SeniorDisabilityManager']);
    this.menu.set('RequestDisabilityChange',['SeniorDisabilityManager']);
    this.menu.set('FundedDetails',['ContactCenterWorker']);
    this.menu.set('LoanDetails',['ContactCenterWorker']);
    this.menu.set('ViewDocuments',['ContactCenterWorker','']);
    this.menu.set('Notes',['ContactCenterWorker']);
    this.menu.set('RejectReason',['ContactCenterWorker']);
    this.menu.set('History',['ContactCenterWorker','InstitutionAdministrator','ContactCenterStudentDetailUpdate']);
    this.menu.set('RegistrationDetails',['ContactCenterWorker','InstitutionAdministrator']);
    this.menu.set('AcademicDetails',['ContactCenterWorker','InstitutionAdministrator']);
    this.menu.set('LegacySingleView',['ContactCenterWorker']);
    this.menu.set('StatusCheck',['ServicingAdmin','InstitutionAdministrator']);
    this.menu.set('ApplicationReport',['ServicingAdmin','InstitutionAdministrator']);
    this.menu.set('FundingList',['ServicingAdmin','InstitutionAdministrator']);
    this.menu.set('Registration',['ServicingAdmin','InstitutionAdministrator']);
    this.menu.set('AcademicResults',['InstitutionAdministrator']);
    this.menu.set('ViewAllAcademicResults',['ServicingAdmin','InstitutionAdministrator']);
    this.menu.set('ViewAllRegistrationDetails',['ServicingAdmin','InstitutionAdministrator']);
    // this.menu.set('AcademicDetails',['ServicingAdmin','InstitutionAdministrator']);
    // this.menu.set('RegistrationDetails',['InstitutionAdministrator']);
    this.menu.set('TopUps/Down',['ServicingAdmin','InstitutionAdministrator']);
    this.menu.set('AppealsStatusCheck',['ServicingAdmin','InstitutionAdministrator']);
    this.menu.set('TopUpAndDownDetails',['ServicingAdmin','InstitutionAdministrator']);
    this.menu.set('ApplyForFunding',['Admin']);
    this.menu.set('NPlusRule',['ServicingAdmin','InstitutionAdministrator']);
    this.menu.set('DeLinkRegistration',['Admin']);
    //this.menu.set('RequiredDocVariation',['Admin','ContactCenterWorker']);
    this.menu.set('DocumentVariations',[ 'Admin']);
    this.menu.set('Escalations',['Admin','ServicingAdmin','ContactCenterWorker']);
    this.menu.set('AppealReopenReason',['Admin','ServicingAdmin','ContactCenterWorker']);
    this.menu.set('AppealReprocessRejectReason',['Admin','ServicingAdmin','ContactCenterWorker']);

    this.menu.set('ReProcess',['AppealManager']);
    this.menu.set('HourlyProgress',['CaseWorker'])


  }

  public logout() {
    const url = this.configService.getApi() + this.type + '/logout';
    return this.http.get(url, {withCredentials: true});

  }

  public register() {
    const url = this.configService.getApi() + this.type + '/register';
    return this.http.post(url, this.register_);
  }

  public authenticate() {
    let credentials = this.loginBean.username + ':' + this.loginBean.password;
    credentials = btoa(credentials);
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .set('Authorization', 'Basic ' + credentials);
    return this.http.post(this.configService.getApi() + this.type + '/login', this.loginBean, {
      'headers': headers,
      withCredentials: true
    });
  }

  public getProfile() {
    const url = this.configService.getApi() + this.type + '/me';
    return this.http.get(url, {withCredentials: true});
  }


  public getToken() {
    const url = this.configService.getApi() + this.type + '/token';
    return this.http.get(url, {withCredentials: true, responseType: 'text'});
  }


  public getCToken() {
    const url = this.configService.getApi() + this.type + '/contact-centre-token';
    return this.http.get(url, {withCredentials: true, responseType: 'text'});
  }

  public update() {
    const url = this.configService.getApi() + this.type;
    return this.http.patch(url, this.user, {withCredentials: true});
  }

  public save(user: User) {
    const url = this.configService.getApi() + this.type;
    return this.http.put(url, user, {withCredentials: true});
  }

  public canView(user: User,menu: string) {
    const roles = this.menu.get(menu);
    return this.hasAnyRole(user,roles);
  }

  public hasAnyRole(user: User, roles: Array<string>) {

    let ret = false;
    roles.forEach((role : string)=>{
      if(this.hasRole(user,role) === true) {
        ret = true;
        return ret;
      }
    });
    return ret;

  }

  public hasRole(user: User, role: string) {
    let ret = false;
    if (user.role === role) {
      return true;
    }
    if(user.roles) {
      user.roles.forEach((rol: string) =>{
        if(role.toLowerCase() === rol.toLowerCase()) {
          ret = true;
          return ret;
        }
      });
    }
    return ret;
  }


  public resetPassword() {
    const url = this.configService.getApi() + this.type + '/reset-password';
    return this.http.post(url, {'idNumber': this.idNumber, 'robot': this.robot});
  }

  public confirmPasswordResetOtp() {

    const url = this.configService.getApi() + this.type + '/confirm-password-reset-otp';
    return this.http.post(url, this.loginBean);
  }

  public searchByRole(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string, role: string) {

    if (keyword === undefined) {
      keyword = '';
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    const url = this.configService.getApi() + this.type + '/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField + '/' + role;
    return this.http.get(url, {params: httpParams, withCredentials: true});

  }

  public searchByCaseWorkers(page: number, perPage: number, sortOrder: string, sortField: string,user: User, keyword: string, roles: Array<string>,date: Date) {

    if (keyword === undefined) {
      keyword = '';
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    if(user && user.id) {
      httpParams = httpParams.set('caseWorkerId', user.id);
    }
    if(roles) {
      httpParams = httpParams.set('roles', roles.join(', '));
    }
    if(date) {
      httpParams = httpParams.set('date',moment(date).format('yyyy-MM-DD'));
    }
    const url = this.configService.getApi() + this.type + '/case-workers/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField ;
    return this.http.get(url, {params: httpParams, withCredentials: true});

  }

  public searchByRolesForAllocations(page: number, perPage: number, sortOrder: string, sortField: string, keyWord: string, roles: Array<String>) {
    if (keyWord === undefined) {
      keyWord = "";
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.set("keyWord", keyWord);
    httpParams = httpParams.append("roles", roles.join(","));
    httpParams = httpParams.append("page", page);
    httpParams = httpParams.append("pageSize", perPage);
    httpParams = httpParams.append("sortDirection", sortOrder);
    httpParams = httpParams.append("sortByField", sortField);
    const url = `${this.configService.getApi()}${this.type}/list/allocations`;
    return this.http.get(url, {params: httpParams, withCredentials: true});
  }

  public searchByRoles(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string, roles: Array<string>) {

    if (keyword === undefined) {
      keyword = '';
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    roles.forEach((role: string) => {
      httpParams = httpParams.append('roles', role);
    });
    const url = this.configService.getApi() + this.type + '/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField + '/' + roles[0];
    return this.http.get(url, {params: httpParams, withCredentials: true});

  }

  public searchDhaEscalations(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string) {

    if (keyword === undefined) {
      keyword = '';
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);

    const url = this.configService.getApi() + this.type + '/dha-escalations/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField;
    return this.http.get(url, {params: httpParams, withCredentials: true});

  }

  public escalateDHA(escalate: Escalate) {
    const url = this.configService.getApi() + this.type + '/dha-escalation';
    return this.http.post(url, escalate,{withCredentials: true});
  }

  public getRoles() {

    const url = this.configService.getApi() + this.type + '/retrieve/roles';
    return this.http.get(url, {withCredentials: true});

  }

  public downloadDailyCases(user: User,date: Date,roles: Array<string>) {
    const httpHeaders = new HttpHeaders();
    let httpParams = new HttpParams();
    if(user && user.id) {
      httpParams = httpParams.set('caseWorkerId', user.id);
    }
    if(roles) {
      httpParams = httpParams.set('roles', roles.join(', '));
    }
    if(date) {
      httpParams = httpParams.set('date',moment(date).format('yyyy-MM-DD'));
    }
    httpHeaders.set('Accept', 'text/csv');
    const url = this.configService.getApi() + this.type + '/download/case-workers/daily-cases';
    return this.http.get(url, {params: httpParams, withCredentials: true, responseType: 'blob'});

  }

  public updateContactDetails(item: User) {
    const url = this.configService.getApi() + this.type+'/update-contact-details/'+item.idNumber;
    return this.http.post(url, item,{  withCredentials: true });
  }

  public startContactVerification() {
    const url = this.configService.getApi() + this.type + '/start-contact-verification';
    return this.http.get(url,{  withCredentials: true});
  }

  public checkVerificationStatus(code: string) {
    const url = this.configService.getApi() + this.type + '/check-contact-verification-status/'+code;
    return this.http.post(url,null,{  withCredentials: true, responseType: 'text' });
  }
}
